html {
    @apply h-full text-lg;
}

body {
    @apply h-full bg-[#f0f3ff];
}

.button {
    @apply inline-flex shadow-[0px_2px_0px_0px_rgb(16,9,107)] rounded-[5px] px-[72px] py-[7px] text-[16px] uppercase font-bold;

    &:hover {
        @apply no-underline;
    }
}

a {
    @apply text-[#{$color-blue}];

    &:hover {
        @apply underline;
    }
}

h2 {
    @apply text-center font-bold italic text-[18px] md:text-[30px] mb-[20px];
}

.text {
    &--primary {
        @apply text-[#{$color-primary}];
    }

    &--blue {
        @apply text-[#{$color-blue}];
    }
}

section {
    @apply relative w-full flex flex-col items-center py-[60px] px-[15px];

    h2 {
        @apply mb-[70px] max-w-[450px] md:max-w-[605px] leading-[46px] text-[34px] font-extrabold;
    }

    > p {
        @apply text-[16px];
    }

    .headline {
        @apply text-[16px] md:text-[18px] mb-[15px] text-[#{$color-blue}];
    }
}

svg {
    width: 100%;
    height: 100%;
}

@keyframes button-base-loading-animation {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
}
