.icon-text {
  @apply flex gap-[15px] mb-[60px] max-w-[380px];
  flex: 0 50%;

  img {
    @apply max-w-[90px];
  }

  div {

    h2 {
      @apply text-[24px];
      margin: 15px 0 15px 0;
      font-weight: normal;
      font-style: normal;
      text-align: left;
      line-height: normal;
    }

    p {
      @apply text-[16px] leading-[24px] max-w-[265px];

      :empty {
        @apply hidden;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .icon-text {
    flex: 0 100%;
  }
}
