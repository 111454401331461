.contact-bubble-wrapper {
  @apply fixed right-[10px] bottom-[10px] z-[999] flex flex-col items-end;

  input[type=checkbox] {
    @apply hidden;

    &:checked {

      ~.tooltip {
        @apply block;
      }
    }
  }

  .tooltip {
    @apply hidden absolute w-[190px] translate-y-[-120%];
  }

  .contact-bubble {
    @apply w-[68px] h-[68px] md:w-[88px] md:h-[88px] rounded-full bg-[#ffdb01] hover:bg-[#f1cf00] text-[#2e2e2e] shadow-[0_2px_0_0_#B19800] flex items-center justify-center text-white cursor-pointer text-[28px] md:text-[38px];

    i {
      @apply text-[#2e2e2e];
    }
  }
}
