header {
    @apply relative bg-white h-[413px] md:h-[421px] w-full overflow-hidden flex flex-col items-center px-[10px] pt-[10px] pb-[10px] md:pb-[22px];
  
    &:before {
      @apply transition-all duration-500;
      @apply content-[''] absolute bg-[#ffdb01] w-[500px] h-[448px] rounded-[16px] right-[50%] top-0 translate-x-[49px] md:translate-x-[-15px] translate-y-[72px] md:translate-y-[164px] rotate-[15deg] origin-top-left;
    }
  
    &:after {
      @apply transition-all duration-500;
      @apply content-[''] absolute bg-[#4546e7] w-[9999px] h-[9999px] rounded-[16px] left-[50%] bottom-0 translate-x-[9px] translate-y-[-84px] md:translate-x-[-26px] md:translate-y-[-78px] rotate-[15deg] origin-bottom-left;
    }
  
    > * {
      @apply z-[1] w-full md:max-w-[#{$page-width}];
    }

  nav {
    @apply z-[11] w-full md:max-w-[#{$page-width}] md:h-[#{$nav-height}] flex flex-row items-center gap-[120px];

    .brand {

    }

    ol {
      @apply flex md:gap-[35px] hidden md:flex;

      li {
        @apply text-sm uppercase;

        a {
          @apply text-[#4d40f2];
          text-shadow: 0 0 10px #fff;
        }

        &:last-child {
          a {
            @apply text-white;
            text-shadow: none;
          }
        }
      }
    }
  }

  .content-wrapper {
    @apply z-10 h-full;

    .content {
      @apply flex flex-col justify-between h-full pt-[35px] md:pt-[42px];

      .headline {
        @apply font-bold italic text-[24px] leading-[34px] md:leading-[46px] md:text-[34px] text-[#{$color-blue}] w-[70%] md:w-full;
        text-shadow: 0 0 10px #fff;
      }

      .tariff {
        @apply md:px-[20px];

        &-headline {
          @apply text-[#4c40ef] text-[14px] md:text-[20px] font-bold italic leading-none;
        }

        &-name {
          @apply text-[#373737] font-bold italic text-[18px] leading-[25px] md:text-[30px] md:leading-none flex flex-row;

          .info {
            @apply rounded-full h-[18px] w-[18px] bg-[#2c1fd1] hover:bg-[#{$color-blue}] cursor-pointer text-white flex justify-center items-center text-[14px] ml-[6px];
          }

          .tooltip-wrapper {
            @apply absolute left-[50px] bottom-[-158px] hidden;

            .tooltip {
              @apply w-[160px] md:w-[220px] before:left-[-27px] before:bottom-[138px] md:before:bottom-[145px];

              .tooltip-content {

                ul {

                  li {
                    @apply text-[16px] not-italic leading-[28px] font-medium;

                    &.spacer {
                      @apply h-[1px] bg-[#4c40ef] m-[10px];
                    }
                  }
                }
              }
            }
          }


          input[type=checkbox] {
            @apply hidden;

            &:checked {

              ~ .tooltip-wrapper {
                @apply block;
              }
            }
          }
        }

        .price-wrapper {
          @apply flex gap-[10px] flex-col md:flex-row items-start md:items-end max-w-max;

          .strikeprice {
            @apply text-[16px] text-[#{$color-blue}] leading-normal;

            span {
              @apply line-through font-medium;
            }
          }
        }
      }
    }

    .header-image {
      @apply flex absolute z-[-1] h-[330px] w-[196px] bottom-0 right-0 md:h-[421px] md:w-[587px] md:left-[50%] md:top-0 md:right-[unset];
      @apply bg-contain bg-no-repeat bg-center;
      @apply bg-[image:var(--header-image-mobile)] md:bg-[image:var(--header-image-desktop)];
    }

    .add-to-cart {
      @apply mt-[10px];

      .button {
        @apply bg-[#{$color-blue}] hover:bg-[#2c1fd1] text-white;
      }
    }
  }
}
