/* Accordion styles */
.accordion {
  @apply overflow-hidden mt-4;

  &__item {
    @apply flex gap-[15px] w-full overflow-hidden text-[16px] leading-[24px] border-b-[1px] border-[#{$color-blue}];

    &:first-child  {
      @apply border-t-[1px] border-[#{$color-blue}];
    }

    input[type=checkbox] {
      @apply hidden;

      &:checked {

        ~ .accordion__item__id {
          @apply items-start;
        }

        ~ .accordion__item__content {

          > p {
            @apply overflow-visible whitespace-normal;
          }
        }

        ~ .accordion__item__label {

          i {
            @apply -rotate-[135deg];
          }
        }
      }
    }

    &__id {
      @apply flex justify-center py-[20px];
    }

    &__content {
      @apply bg-white flex items-center justify-center min-w-[0] py-[20px] transition-all duration-150;

      p {
        @apply overflow-hidden whitespace-nowrap text-ellipsis;
      }
    }

    &__label {
      @apply cursor-pointer my-[10px] bg-[#{$color-blue}] hover:bg-[#2228ca] text-white flex shrink-0 items-center justify-center rounded-full h-[40px] w-[40px] text-center;

      i {
        @apply transition-all duration-150;
        @apply border-solid border-white rotate-45 inline-block m-2 p-[3px] border-r-[2px] border-b-[2px] rotate-45; 
      }
    }
  }
}
