.theme-black-week {

    header {
        @apply bg-white;
        @apply bg-[linear-gradient(180deg,_white_0%,_white_14%,_#2a2a2a_14%,_#2a2a2a_100%)] md:bg-[linear-gradient(180deg,_white_0%,_white_22%,_#2a2a2a_22%,_#2a2a2a_100%)];

        .content-wrapper {

            .content {

                .headline {
                    @apply text-white;
                    text-shadow: none;
                }
            }
        }
    }

    .tariff-wrapper {

        .tariff-container {

            &.data-label {

                &:before {
                    @apply bg-[#2a2a2a] text-white;
                }
            }
        }
    }
}
