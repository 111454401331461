.tooltip {
  @apply max-w-[220px] text-center shadow-[0_2px_9px_0_rgba(0,0,0,0.14)] block relative mb-[21px] rounded-[5px];

  &:before {
    @apply absolute bottom-[-9px] left-[50%] content-[''] border-[16px] border-solid border-[transparent] border-b-[white] rotate-45 origin-bottom drop-shadow-[0_0_.5rem_rgba(0,0,0,.28)];
  }

  .tooltip-content {
    @apply flex flex-col relative bg-white px-[10px] py-[15px] rounded-[5px];
  }
}
