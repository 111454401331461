@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';
@import 'roboto-font';
@import 'base';
@import 'layout/header';
@import 'layout/footer';
@import 'widgets/icon-text';
@import 'widgets/accordion';
@import 'widgets/trustedshops';
@import 'widgets/tooltip';
@import 'widgets/contact-bubble';

@import 'sections/content';
@import 'sections/tariff';

@import "black-week";
