.content-wrapper {
  @apply w-full md:max-w-[#{$page-width}] flex;

  .content {
    @apply flex flex-col justify-between h-full pt-[35px] md:pt-[42px];

    .headline {
      @apply font-bold italic text-[24px] leading-[34px] md:leading-[46px] md:text-[34px] text-[#4c40ef];
    }
  }

  .add-to-cart {
    @apply mt-[10px];

    .button {
      @apply bg-[#4C40EF] hover:bg-[#2c1fd1] text-white;
    }

    .disabled {
      @apply bg-[#fff];
      background-image: -webkit-linear-gradient(315deg, #e5e5e5 20%, transparent 20%, transparent 52%, #e5e5e5 52%, #e5e5e5 75%, transparent 75%, transparent) !important;
      background-image: linear-gradient(135deg, #e5e5e5 20%, transparent 20%, transparent 52%, #e5e5e5 52%, #e5e5e5 75%, transparent 75%, transparent) !important;
      background-size: 5px 5px !important;
      border: 1px solid #e5e5e5 !important;
      color: #7a8394 !important;
    }
  }
}


.tariff {
  @apply flex flex-col gap-[5px];

  &-headline {
    @apply text-[#4c40ef] text-[14px] md:text-[20px] font-bold italic leading-none;
  }

  &-name {
    @apply text-[#373737] font-bold italic text-[18px] leading-[25px] md:text-[30px] md:leading-none flex flex-row;

    .info {
      @apply rounded-full h-[18px] w-[18px] bg-[#2c1fd1] text-white flex justify-center items-center text-[14px] ml-[6px];
    }
  }

  &-price {
    @apply grid text-[#4c40ef] w-[min-content] mt-[10px];
    grid-template-areas:
          "runtime euro cent info"
          "runtime euro currency .";

    .runtime {
      @apply text-[12px] flex items-end;
      grid-area: runtime;
      grid-row: 1 / 3;
    }

    .euro {
      @apply text-[60px] font-bold leading-none;
      grid-area: euro;
      grid-row: 1 / 3;
    }

    .cent {
      @apply text-[26px] font-bold leading-none flex ml-[-10px];
      grid-area: cent;
    }

    .currency {
      @apply text-[26px] font-bold leading-none mt-[-8px] flex justify-end;
      grid-area: currency;
    }

    .info {
      @apply flex ml-1.5 items-start text-[12px] font-bold leading-none;
      grid-area: info;
    }
  }
}

.about-content {
  @apply bg-[#{$color-blue}] text-white pb-0;

  .headline {
    @apply text-white text-[16px] uppercase;
  }

  .content-wrapper {
    @apply flex gap-0 md:gap-[70px] flex-col md:flex-row w-full md:max-w-[#{$page-width-secondary}];

    .column {
      @apply flex flex-col shrink grow basis-0;

      &:last-child {
        @apply pb-[245px] bg-[url(/img/content-bg.png)] bg-no-repeat bg-bottom md:bg-left-bottom;
      }

      .text-item {
        @apply mb-[50px] px-0;

        h3 {
          @apply text-[24px] font-normal mb-[20px];
        }

        p {
          @apply text-[16px] font-normal leading-[24px];
        }
      }
    }
  }
}

.tariff-content {
  @apply px-0;
  background: linear-gradient(0deg, white 38%, $color-paleblue 38%);

  .headline {
    @apply px-[15px];
  }

  h2 {
    @apply px-[15px];
  }
}

.trust-content {
  @apply bg-white;

  .content-wrapper {
    @apply w-full md:max-w-[#{$page-width-secondary}];
  }
}

.legal-content {
  @apply flex bg-white;

  .headline {
    @apply text-[#4c40ef] uppercase;
  }

  .content-wrapper {
    @apply text-[16px] leading-[24px];
  }
}
