footer {
  @apply relative w-full flex flex-col items-center py-[40px] md:py-[20px] px-[30px] bg-[#{$color-paleblue}];

  nav {
    @apply w-full gap-[40px] md:gap-0 flex flex-col md:flex-row items-center md:max-w-[#{$page-width}];

    ol {
      @apply flex flex-col md:flex-row gap-[40px] md:gap-[30px] w-full md:justify-end text-center;

      a {
        @apply text-[#4d40f2] text-[16px];
      }
    }
  }
}
