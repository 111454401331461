$root: ".tariff";

#{$root} {
    &-content {
        h2 {
            @apply mb-[24px];
        }
    }

    &-switch {
        @apply relative bg-white flex flex-row items-center justify-between z-[1] mt-[8px] md:mt-0 mb-[28px] md:mb-[60px];
        border: {
            radius: 3.333rem;
        }
        width: 18.889rem;

        @media (min-width: 560px) {
            width: 30.111rem;
        }

        @media (min-width: 768px) and (max-width: 1300px) {
            margin: {
                bottom: 30px;
            }
        }

        &-button {
            @apply w-1/2 leading-4 md:leading-10 px-6 uppercase;
            border: {
                radius: 3.333rem;
            }
            font: {
                size: 0.889rem;
                weight: 700;
            }
            padding: {
                bottom: 0.64rem;
                top: 0.64rem;
            }
            width: 50%;

            @screen md {
                padding: {
                    bottom: 0;
                    top: 0;
                }
            }

            &--selected {
                @apply bg-[#{$color-blue}] hover:bg-[#{$color-darkblue}] text-white;
            }
        }
    }

    &-wrapper {
        @apply relative w-full flex-row items-center justify-start lg:justify-center gap-[10px] z-[1] px-[15px] hidden;

        @media (max-width: 1300px) {
            @apply py-[30px] overflow-auto whitespace-nowrap;
            -webkit-overflow-scrolling: touch; /* apply iOS momentum scrolling effect to this element */
        }

        &--selected {
            @apply flex;
        }

        #{$root}-container {
            @apply bg-white rounded-[5px] text-center font-normal text-[18px] text-[#2e2e2e] relative px-[10px] pt-[30px] pb-[40px] md:py-[30px] shadow-[0_2px_9px_0_rgba(0,0,0,0.14)];

            @media (max-width: 1300px) {
                #{$root}-wrapper {
                    #{$root}-container {
                        @apply inline-block;
                    }
                }
            }

            &.data-label {
                &:before {
                    @apply bg-[#FFDB01] text-[#2e2e2e] top-[-30px] left-0 right-0 absolute justify-center content-[attr(data-label)] flex rounded-tl-[5px] rounded-tr-[5px] z-[-1] italic font-extrabold text-[16px] pb-[5px];
                    @apply shadow-[rgba(50,50,93,.25)_0_6px_12px_-2px,_rgba(0,0,0,.3)_0_3px_7px_-3px];
                }
            }

            #{$root} {
                @apply rounded-[5px] items-center;

                input[type="checkbox"] {
                    @apply hidden;

                    &:checked {
                        ~ .tariff-options {
                            @apply flex;
                        }

                        ~ .tariff-infos {
                            @apply flex;
                        }

                        ~ .tariff-show-more-button {
                            i {
                                @apply transform rotate-45;
                                top: 3px;
                            }
                        }
                    }
                }

                &-name {
                    @apply text-[30px] font-extrabold italic;
                }

                &-runtime-text {
                    @apply text-[#{$color-blue}];
                }

                &-price {
                    @apply mt-[25px];
                }

                &-strikeprice {
                    @apply text-[#{$color-blue}] text-[16px] leading-normal;

                    span {
                        @apply font-medium line-through;
                    }
                }

                &-usp {
                    @apply mt-[25px] text-[16px] font-medium;
                }

                &-show-more-button {
                    @apply md:hidden absolute bottom-[0] translate-y-[50%] bg-[#{$color-blue}] hover:bg-[#2228ca] text-white h-[41px] w-[41px] flex items-center justify-center rounded-full transition-all duration-150;
                    i {
                        @apply block border-t-2 border-l-2 border-[#fff] border-solid relative;
                        top: -3px;
                        width: 17px;
                        height: 17px;
                        border-radius: 1px;
                        transform: rotate(225deg);
                    }
                }

                &-options {
                    @apply hidden md:flex flex-col my-[25px] mx-auto;

                    p {
                        @apply text-[16px] leading-[28px] text-center font-medium;
                    }
                }

                &-infos {
                    @apply hidden md:flex flex-col justify-center;

                    p {
                        @apply text-[16px] leading-[28px] font-medium;
                    }
                }
            }

            .add-to-cart {
                @apply mt-[25px];

                .button {
                    @apply bg-[#ffdb01] hover:bg-[#f1cf00] text-black shadow-[0_2px_0_0_#B19800];
                }

                .disabled {
                  @apply bg-[#fff];
                  background-image: -webkit-linear-gradient(315deg, #e5e5e5 20%, transparent 20%, transparent 52%, #e5e5e5 52%, #e5e5e5 75%, transparent 75%, transparent) !important;
                  background-image: linear-gradient(135deg, #e5e5e5 20%, transparent 20%, transparent 52%, #e5e5e5 52%, #e5e5e5 75%, transparent 75%, transparent) !important;
                  background-size: 5px 5px !important;
                  border: 1px solid #e5e5e5 !important;
                  color: #7a8394 !important;
                  box-shadow: none !important;
                  cursor: not-allowed !important;

                  &:hover {
                    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
                  }
                }

                .loading {
                    &:before {
                        background: url("/svg/spinner.svg") center/100% no-repeat;
                        animation: button-base-loading-animation 0.9s linear infinite;
                        content: "";
                        display: inline-block;
                        height: 1.3rem;
                        margin: 3px 10px 0 0;
                        width: 1.3rem;
                    }
                }

                + p {
                    @apply text-[16px];
                }
            }

            &.status-recommendation {
                @apply bg-[#F0F3FF] border-[#{$color-blue}] border-[3px] py-[50px];

                .tariff {
                    @apply bg-[#{$color-paleblue}] rounded-[10px] z-[2];
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    ::-webkit-scrollbar {
        @apply w-0 h-0;
    }

    ::-webkit-scrollbar-thumb {
        @apply bg-transparent;
    }

    ::-webkit-scrollbar-track {
        @apply bg-transparent;
    }
}
